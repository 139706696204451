import React, {FC} from "react";
import {Box, Text} from "@mantine/core";

import {
  getImplantRetentionTypeLabel,
  getItemAbutmentMaterialLabel,
  getItemAbutmentTypeLabel,
  getItemInlayCoreHasClavetteLabel,
  getItemInlayCoreMaterialLabel,
  getItemInlayCoreScanPostLabel,
  getItemMaterialLabel,
  getItemShadeLabel,
  getItemTypeWithCountLabel,
  sortItems,
  getItemMultiShadeLabel,
} from "../utils/item.utils";
import {
  getTeethLabel,
  isAnatomyItemType,
  isImplantItemType,
  isRemovableItemType,
} from '../utils/product.utils';
import {
  Product,
  Order
} from '../types/interfaces';
import {
  ItemType,
  TeethShadeType,
  AbutmentType,
} from '../types/enums';
import {
  translateItemMaterial,
} from "../utils/translation.utils";

type ItemsDescriptionProps = {
  product: Product;
  order?: Order;
};

const ItemsDescription: FC<ItemsDescriptionProps> = ({product, shouldTranslate}) => {
  if (!product) return null;
  let items = isAnatomyItemType(product.productType)
    ? product.anatomyItem
    : isImplantItemType(product.productType)
      ? product.implantItem
      : isRemovableItemType(product.productType)
        ? product.removableItem
        : null;
  if (!items || (Array.isArray(items) && items.length < 1)) return null;
  if (!Array.isArray(items)) {
    items = [items];
  }

  const {
    occlusalStaining,
    occlusalContactTightness,
    proximalContactTightness,
    interproximalContactDesign,
    anteriorPosteriorPonticDesign,
  } = (product.itemPreferences?.set ?? product.itemPreferences) || {};

  const showItemOrProductTeeths = (productType: ItemType, itemType: ItemType): boolean => {
    if (isAnatomyItemType(productType)){
      return (true);
    }
    if (isImplantItemType(productType) && itemType !== ItemType.BridgeImplant){
      return (true);
    }
    return (false);
  };

  const getItemName = (item, product, shouldTranslate) => {
    const itemTypeWithCount = getItemTypeWithCountLabel(item, shouldTranslate);
    const teethLabel = getTeethLabel(
      item.itemType,
      showItemOrProductTeeths(product.productType, item.itemType)
        ? item.teethLabel
        : product.teeth,
      item.teeth,
      shouldTranslate,
    );
    return `${itemTypeWithCount} ${teethLabel}`;
  };

  return (
    <Box>
      {sortItems(items, product.productType).map((item: any) => {
        if (item.itemType === ItemType.BRIDGE_PONTIC) {
          return null;
        }
        return (
          <Box key={item.id}>
            <Text size="sm" style={{ textAlign: "left" }}>{getItemName(item, product, shouldTranslate)}</Text>
            {item.itemType !== ItemType.INLAY_CORE && (
              <div style={{ marginLeft: "1rem", textAlign: 'left' }}>
                {item?.itemMaterial &&
                  <Text size="sm">
                    {`- ${shouldTranslate ? translateItemMaterial(item?.itemMaterial?.id) : getItemMaterialLabel(item)}`}
                  </Text>
                }
                {item?.itemShade &&
                  <Text size="sm">
                    {`- ${getItemShadeLabel(item)}`}
                  </Text>
                }
                {item?.teethShade && (
                  <Text size="sm">{`- ${getItemShadeLabel(item)}`}</Text>
                )}
                {item?.teethshadeType === TeethShadeType.MULTI_SHADE && (
                  <>
                    <Text size="sm">- {shouldTranslate ? "Shade" : "Teinte"}:</Text>
                    <Text size="sm" style={{ whiteSpace: "pre-line", marginLeft: "1rem" }}>{`${getItemMultiShadeLabel(item)}`}</Text>
                  </>
                )}
              </div>
            )}
            <div style={{ marginLeft: "1rem", textAlign: 'left' }}>
              {(
                (occlusalStaining && occlusalStaining !== 'MEDIUM') ||
                (occlusalContactTightness && occlusalContactTightness !== 'MEDIUM') ||
                (proximalContactTightness && proximalContactTightness !== 'MEDIUM') ||
                (interproximalContactDesign && interproximalContactDesign !== 'NORMAL_CONTACT_POINT') ||
                anteriorPosteriorPonticDesign
              ) &&
                <>
                  <Text size="sm">{`- Preferences`}</Text>
                  <div style={{ marginLeft: "1rem" }}>
                    {(occlusalStaining && occlusalStaining !== 'MEDIUM') && <Text size="sm">{`- Occlusal Staining = ${occlusalStaining}`}</Text>}
                    {(occlusalContactTightness && occlusalContactTightness !== 'MEDIUM') && <Text size="sm">{`- Occlusal Contact Tightness = ${occlusalContactTightness}`}</Text>}
                    {(proximalContactTightness && proximalContactTightness !== 'MEDIUM') && <Text size="sm">{`- Proximal Contact Tightness = ${proximalContactTightness}`}</Text>}
                    {(interproximalContactDesign && interproximalContactDesign !== 'NORMAL_CONTACT_POINT') && <Text size="sm">{`- Interporximal Contact Design = ${interproximalContactDesign || 'NORMAL_CONTACT_POINT'}`}</Text>}
                    {anteriorPosteriorPonticDesign && <Text size="sm">{`- Anterior Posterior Pontic Design = ${anteriorPosteriorPonticDesign}`}</Text>}
                  </div>
                </>
              }
            </div>
            <div style={{marginLeft: "1rem", textAlign: "left"}}>
              {getItemInlayCoreMaterialLabel(item) &&
                <Text size="sm">{`- ${getItemInlayCoreMaterialLabel(item, shouldTranslate)}`}</Text>
              }
              {getItemInlayCoreHasClavetteLabel(item) &&
                <Text size="sm">{`- ${getItemInlayCoreHasClavetteLabel(item, shouldTranslate)}`}</Text>
              }
              {getItemInlayCoreScanPostLabel(item) &&
                <Text size="sm">{`- ${getItemInlayCoreScanPostLabel(item)}`}</Text>
              }
              {getImplantRetentionTypeLabel(item) &&
                <Text size="sm">{`- ${getImplantRetentionTypeLabel(item, shouldTranslate)}`}</Text>
              }
              {getItemAbutmentMaterialLabel(item) &&
                <Text size="sm">{`- ${getItemAbutmentMaterialLabel(item, shouldTranslate)}`}</Text>
              }
              {(item?.itemType === ItemType.IMPLANT && item.implantReferenceSuggestion) &&
                <Text size="sm">{`- Implant reference suggestion: ${item.implantReferenceSuggestion}`}</Text>
              }
              {(item?.itemType === ItemType.IMPLANT && item.scanBodySuggestion) &&
                <Text size="sm">{`- Scan body: ${item.scanBodySuggestion}`}</Text>
              }
              {(item?.itemType === ItemType.IMPLANT && item.implantPlatform) &&
                <Text size="sm">{`- Implant platform: ${item.implantPlatform}`}</Text>
              }
              {(item?.itemType === ItemType.IMPLANT && item.implantReference) &&
                <>
                  <Text size="sm">{`- Implant brand: ${item.implantReference?.brand}`}</Text>
                  <Text size="sm">{`- Implant reference: ${item.implantReference?.label}`}</Text>
                </>
              }
              {(item?.itemType === ItemType.IMPLANT && item.standardAbutmentSuggestion) &&
                <Text size="sm">{`- Standard abutment suggestion: ${item.standardAbutmentSuggestion}`}</Text>
              }
              {(getItemAbutmentTypeLabel(item) && !shouldTranslate) &&
                <Text size="sm">{`- Abutment type: ${getItemAbutmentTypeLabel(item, shouldTranslate)}`}</Text>
              }
              {(item.abutmentType && shouldTranslate && item.abutmentType === AbutmentType.STANDARD) &&
                <Text size="sm">- Standard abutment, we will ship you the parts, please confirm abutment height and diameter</Text>
              }
              {(item.abutmentType && shouldTranslate && item.abutmentType === AbutmentType.STANDARD_GENERIC) &&
                <Text size="sm">- Standard 3rd party abutment</Text>
              }
              {(item.abutmentType && shouldTranslate && item.abutmentType === AbutmentType.CUSTOM) &&
                <Text size="sm">- Abutment type: {item.abutmentType}</Text>
              }
            </div>
          </Box>
        );
      })}
    </Box>
  );
};

export default ItemsDescription;
