import { gql } from '@apollo/client';

export const SEND_MESSAGE = gql`
  mutation SendMessage($args: ChatCreateInput!) {
    sendMessage(args: $args) {
      id
      createdAt
      orderId
      files {
        id
      }
      updatedAt
      userGroupId
      message
    }
  }
`;

export const GET_ALL_MESSAGES_OF_ORDER = gql`
  query GetAllChatsOfOrder($where: ChatWhereInput!) {
    getAllChatsOfOrder(where: $where) {
      createdAt
      files {
        fileType
        key
        createdAt
        userId
        orderId
      }
      id
      orderId
      userGroup {
        name
        groupType
        id
      }
      userGroupId
      message
    }
  }
`;


export const GET_LAST_UNREAD_MESSAGES_OF_EACH_ORDER = gql`
  query GetLastUnreadOfEachOrder {
    getLastUnreadOfEachOrder {
      id
      createdAt
      userGroup {
        name
        groupType
        id
      }
      order {
        orderReference
        id
        status
      }
      message
    }
  }
`;

export const UPDATE_MESSAGES = gql`
  mutation UpdateMessages($data: ChatUpdateManyMutationInput!, $where: ChatWhereInput!) {
    updateMessages(data: $data, where: $where)
  }
`;
