import { useState } from 'react';
import {
  Button,
  Modal,
  Tooltip,
} from "@mantine/core";
import {
  useMutation,
  useQuery,
} from '@apollo/client';
import { notifications } from "@mantine/notifications";
import { useNavigate } from 'react-router-dom';
import { Notifications } from "@mantine/notifications";

import { STYLE } from '../types/constants';
import { OrderStatus, UserType } from '../types/enums';
import {
  UPDATE_ORDER,
} from '../gql/orders';
import {
  isUserStaff,
  isUserProvider,
} from "../utils/user.utils";
import {
    GET_ALL_PROVIDERS,
} from "../gql/users";
import RedoModal from './modals/RedoModal';
import UpdateDeliveryDateModal from './modals/updateDeliveryDateModal';
import UpdateMaterialAndLotModal from './modals/updateMaterialAndLotModal';
import SendOrderToSupplierModal from "./modals/sendOrderToSupplierModal";
import { sendMailToProvider } from '@jasper/shared'

const HeaderOrderView = ({ order, user, refetch }) => {

  const [sendMail] = useMutation(sendMailToProvider);
  const [openMaterialAndLotModal, setOpenMaterialAndLotModal] = useState(false);
  const [openDeliveryDateModal, setOpenDeliveryDateModal] = useState(false);
  const [openRedoModal, setOpenRedoModal] = useState(false);
  const [openSendToSupplierModal, setOpenSendToSupplierModal] = useState(false);

  const navigate = useNavigate();

  const [updateOrder] = useMutation(UPDATE_ORDER);
  const { data: allProviders } = useQuery(GET_ALL_PROVIDERS);

  const getAllUntranslatedComment = () => {
    return (order?.orderComment ?? []).filter((comment) => !comment.translatedComment) ?? []
  };

  const archiveAndSetAsDelivered = async () => {
    try {
      await updateOrder({
        variables: {
          where: {
            id: order.id,
          },
          data: {
            status: {
              set: OrderStatus.DELIVERED,
            },
            isArchived: {
              set: true
            }
          }
        }
      });
      refetch();
      notifications.show({
        title: "Order is correctly archived and set as delivered",
        color: "green",
        message: "",
      });
    } catch (e) {
      console.error(e);
      notifications.show({
        title: "Error while trying to archived order and set it as delivered",
        color: "red",
        message: "",
      });
    }
  };

  const startProduction = async(providerGroupId?: string) => {
    try {
      await updateOrder({
        variables: {
          where: {
            id: order.id,
          },
          data: {
            status: {
              set: providerGroupId ? OrderStatus.WAITING_FOR_PRODUCTION : OrderStatus.PRODUCING,
            },
            isArchived: {
              set: false
            },
            provider: providerGroupId
              ? {
                connect: {
                  id: providerGroupId
                }
              }
              : undefined,
          }
        }
      });
      refetch();
      if (providerGroupId) {
        await sendMail(
          {
            variables: {
              orderId: order.id
            }
          }
        );
      }
      notifications.show({
        title: "Order is correctly set as producing",
        color: "green",
        message: "",
      });
    } catch (e) {
      console.error(e);
      notifications.show({
        title: "Error while trying to set order as producing",
        color: "red",
        message: "",
      });
    }
  };

  const setOrderOnHold = async () => {
    if (order.status === OrderStatus.ON_HOLD){
      notifications.show({
        title: "Order is already on hold",
        color: "red",
        message: "",
      });
      return ;
    }
    try {
      await updateOrder({
        variables: {
          where: {
            id: order.id,
          },
          data: {
            status: {
              set: OrderStatus.ON_HOLD,
            },
          }
        }
      });
      refetch();
      notifications.show({
        title: "Order is correctly set on hold",
        color: "green",
        message: "",
      });
    } catch (e) {
      console.error(e);
      notifications.show({
        title: "Error while trying to set order on hold",
        color: "red",
        message: "",
      });
    }
  };

  const markAsProducingInTransit = async () => {
    if (order.status === OrderStatus.PRODUCING_IN_TRANSIT){
      notifications.show({
        title: "Order is already in PRODUCING_IN_TRANSIT status",
        color: "red",
        message: "",
      });
      return ;
    }
    if (order.status !== OrderStatus.PRODUCING){
      notifications.show({
        title: "Cannot set order as PRODUCING_IN_TRANSIT cause status is not in PRODUCING state",
        color: "red",
        message: "",
      });
      return ;
    }
    try {
      await updateOrder({
        variables: {
          where: {
            id: order.id,
          },
          data: {
            status: {
              set: OrderStatus.PRODUCING_IN_TRANSIT,
            },
          }
        }
      });
      refetch();
      notifications.show({
        title: "Order is correctly set as producting in transit",
        color: "green",
        message: "",
      });
    } catch (e) {
      console.error(e);
      notifications.show({
        title: "Error while trying to set order as producting in transit",
        color: "red",
        message: "",
      });
    }
  };

  const markOrderAsShipped = async() => {
    if (order.status === OrderStatus.SHIPPED){
      notifications.show({
        title: "Order is already in SHIPPED status",
        color: "red",
        message: "",
      });
      return ;
    }
    if (order.status !== OrderStatus.PRODUCING_IN_TRANSIT){
      notifications.show({
        title: "Cannot set order as SHIPPED cause status is not in PRODUCING_IN_TRANSIT state",
        color: "red",
        message: "",
      });
      return ;
    }
    try {
      await updateOrder({
        variables: {
          where: {
            id: order.id,
          },
          data: {
            status: {
              set: OrderStatus.SHIPPED,
            },
          }
        }
      });
      refetch();
      notifications.show({
        title: "Order is correctly set as SHIPPED",
        color: "green",
        message: "",
      });
    } catch (e) {
      console.error(e);
      notifications.show({
        title: "Error while trying to set order as SHIPPED",
        color: "red",
        message: "",
      });
    }
  };

  const markAsDelivered = async() => {
    try {
      await updateOrder({
        variables: {
          where: {
            id: order.id,
          },
          data: {
            status: {
              set: OrderStatus.DELIVERED,
            },
          }
        }
      });
      refetch();
      notifications.show({
        title: "Order is correctly set as delivered",
        color: "green",
        message: "",
      });
    } catch (e) {
      console.error(e);
      notifications.show({
        title: "Error while trying to set order as delivered",
        color: "red",
        message: "",
      });
    }
  };

  const getTextForArchivedButton = (order) => {
    if (order.status !== OrderStatus.DELIVERED && order.isArchived === true){
      return "Set order as delivered";
    }
    if (order.status === OrderStatus.DELIVERED && order.isArchived === false){
      return "Archive order";
    }
    return "Archive & set as delivered";
  };

  return (
    <div style={{ paddingBottom: '1rem', float: 'right' }}>
      <Notifications position="bottom-center" />
      <Modal
        opened={openMaterialAndLotModal}
        onClose={() => setOpenMaterialAndLotModal(false)}
      >
        <UpdateMaterialAndLotModal
          order={order}
          closeModal={() => setOpenMaterialAndLotModal(false)}
          refetch={refetch}
          user={user}
        />
      </Modal>
      <Modal
        opened={openDeliveryDateModal}
        onClose={() => setOpenDeliveryDateModal(false)}
      >
        <UpdateDeliveryDateModal
          order={order}
          closeModal={() => setOpenDeliveryDateModal(false)}
          refetch={refetch}
        />
      </Modal>
      <Modal
        opened={openRedoModal}
        onClose={() => setOpenRedoModal(false)}
      >
        <RedoModal
          order={order}
          closeModal={() => setOpenRedoModal(false)}
          refetch={refetch}
        />
      </Modal>
      <Modal
        opened={openSendToSupplierModal}
        onClose={() => setOpenSendToSupplierModal(false)}
      >
        <SendOrderToSupplierModal
          suppliers={
            (allProviders?.getAllProviders ?? []).map((provider: { id: string, firstname: string, lastname: string }) => {return { id: provider.id, name: provider.name }})
          }
          onSubmit={(selectedSupplierId: string) => {
            if (!selectedSupplierId){
              notifications.show({
                title: "You must select a provider",
                color: "red",
                message: "",
              });
              return ;
            }
            startProduction(selectedSupplierId);
            setOpenSendToSupplierModal(false);
          }}
        />
      </Modal>
      {(isUserStaff(user) && (order.status === OrderStatus.CONFIRMED || order.status === OrderStatus.ON_HOLD || order.status === OrderStatus.DELIVERED)) &&
        <>
          {(getAllUntranslatedComment().length > 0) ? (
            <Tooltip label="You need to translated all comments before start production">
              <Button onClick={() => setOpenSendToSupplierModal(true)} disabled style={{ backgroundColor: "lightgrey" }}>Send order to suppliers</Button>
            </Tooltip>
          ) : (
            <Button onClick={() => setOpenSendToSupplierModal(true)} style={{ backgroundColor: STYLE.primary }} >Send order to suppliers</Button>
          )}
        </>
      }
      {((isUserProvider(user) || isUserStaff(user)) && order.status !== OrderStatus.DELIVERED && order.status !== OrderStatus.ON_HOLD) &&
        <>
          &nbsp;
          <Button onClick={() => setOrderOnHold()} style={{ backgroundColor: STYLE.primary }}>Set order on hold</Button>
        </>
      }
      {isUserProvider(user) &&
        <>
          &nbsp;
          <Button onClick={() => setOpenMaterialAndLotModal(true)} style={{ backgroundColor: STYLE.primary }}>Add material list and lot number</Button>
        </>
      }
      {(isUserStaff(user) && order.status === OrderStatus.PRODUCING_IN_TRANSIT) &&
        <>
          &nbsp;
          <Button onClick={markOrderAsShipped} style={{ backgroundColor: STYLE.primary }}>Mark as shipped from France to France</Button>
        </>
      }
      {isUserStaff(user) &&
        <>
          &nbsp;
          <Button onClick={() => navigate(`/orders/${order.id}/edit/`)} style={{ backgroundColor: STYLE.primary }}>Edit order</Button>
        </>
      }
      {(isUserStaff(user) && order.status !== OrderStatus.DELIVERED && order.status !== OrderStatus.DRAFT) &&
        <>
          &nbsp;
          <Button onClick={() => setOpenDeliveryDateModal(true)} style={{ backgroundColor: STYLE.primary }}>Update delivery date</Button>
        </>
      }
      {(isUserStaff(user) && order.status === OrderStatus.DELIVERED) &&
        <>
          &nbsp;
          <Button onClick={() => setOpenRedoModal(true)} style={{ backgroundColor: STYLE.primary }}>Mark as redo</Button>
        </>
      }
      {(isUserProvider(user) && order.status === OrderStatus.WAITING_FOR_PRODUCTION) &&
        <>
          &nbsp;
          <Button onClick={() => startProduction()} style={{ backgroundColor: STYLE.primary }}>Start production</Button>
        </>
      }
      {(isUserProvider(user) && order.status === OrderStatus.PRODUCING) &&
        <>
          &nbsp;
          <Button onClick={markAsProducingInTransit} style={{ backgroundColor: STYLE.primary }}>Mark as shipped to France</Button>
        </>
      }
      {(isUserStaff(user) && order.status === OrderStatus.SHIPPED) &&
        <>
          &nbsp;
          <Button onClick={markAsDelivered} style={{ backgroundColor: STYLE.primary }}>Mark as delivered</Button>
        </>
      }
      {(isUserStaff(user) && (order.status !== OrderStatus.DELIVERED || order.isArchived === false)) &&
        <>
          &nbsp;
          <Button onClick={archiveAndSetAsDelivered} style={{ backgroundColor: STYLE.primary }}>{getTextForArchivedButton(order)}</Button>
        </>
      }
    </div>
  )
}

export default HeaderOrderView;
