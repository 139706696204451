import React from 'react';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';

import { GET_ORDER_BY_UNIQUE_ATTRIBUTE, GET_ALL_MESSAGES_OF_ORDER, SEND_MESSAGE, UPDATE_MESSAGES } from '/@/gql';
import { FileType, NewOrderViewPage, useFiles  } from '@jasper/shared';
import { UseAuth } from '/@/contexts';
import { useEffect, useState } from 'react';
import {io} from "socket.io-client";
import { getApiUrl } from '../../config';

const NewOrderView = () => {

  const { order_id } = useParams();
  const { user } = UseAuth();

  if(!order_id) {
    return
  }

  const { data, refetch } = useQuery(
    GET_ORDER_BY_UNIQUE_ATTRIBUTE,
    {
      variables: {
        where: {
          id:  order_id
        }
      }
    }
  );
  const {getPresignedDownloadUrl, getFileUrl, uploadFileToS3} =
    useFiles(order_id);

  const [message, setMessage] = useState('');
  const [filesToSend, setFilesToSend] = useState<File[]>([]);
  const [messagesWithFilePath, setMessagesWithFilePath] = useState<any[]>([]);
  const {data: messages, refetch: refetchMessage} = useQuery(
    GET_ALL_MESSAGES_OF_ORDER,
    {
      variables: {
        where: {
          orderId: {
            equals: order_id
          }
        }
      }
    }
  );

  const [send] = useMutation(SEND_MESSAGE);
  const [updateMessages] = useMutation(UPDATE_MESSAGES);

  const getFilesWithPath = async(messages) => {
    return await Promise.all(
      messages
        .getAllChatsOfOrder
        .map(async (msg) => {
          return {
            ...msg,
            files: await Promise.all(msg.files.map(async(file)=> {
              return {
                path: await getFileUrl(file.key),
                key: file.key,
                fileType: file.fileType
              }
            }))
          }
        })
    )
  }

  const markmessagesAsRead = () => {
    updateMessages(
      {
        variables: {
          data: {
            read: {
              set: true
            }
          },
          where: {
            orderId: {
              equals: order_id
            },
            userGroupId: {
              not: {
                equals: user?.userGroupId
              }
            }
          }
        }
      }
    )
  }

  const sendMessage = async () => {
    if(filesToSend.length !== 0) { 
      uploadFileToS3(filesToSend, FileType.CHAT_FILE).then(async(data) => {
        if(data && data.length !== 0 ) {
          await send(
            {
              variables: {
                args: {
                  createdAt: new Date(),
                  order: {
                    connect: {
                      id: order_id
                    }
                  },
                  updatedAt: new Date(),
                  userGroup: {
                    connect: {
                      id: user.userGroupId
                    }
                  },
                  message: message,
                  files:{
                    create: data
                  }
                },
              }
            }
          )
        }
        setFilesToSend([])
      })
    }
    else {
      await send(
        {
          variables: {
            args: {
              createdAt: new Date(),
              order: {
                connect: {
                  id: order_id
                }
              },
              updatedAt: new Date(),
              userGroup: {
                connect: {
                  id: user.userGroupId
                }
              },
              message: message,
            },
          }
        }
      )
    }
  }

  useEffect(() => {
    markmessagesAsRead()
    const socket = io(getApiUrl(),
      {
        query: {
          type: 'chat',
          user_group_id: user?.userGroupId,
          order_id: order_id
        },
      });

    socket.connect()

    socket.on('chat', () => {
      markmessagesAsRead()
      refetchMessage()
    })
    return () => {
      socket.disconnect()
      socket.off('connect')
      socket.off('disconnect')
      socket.off('chat')
    }
  }, [])

  useEffect(()=>{
    if (messages) {
      getFilesWithPath(messages).then((data)=> {
        setMessagesWithFilePath(data)
      })
    }
  }, [messages])

  return (
    <NewOrderViewPage
      order={data?.getOrderByUniqueAttribute}
      user={user}
      refetch={refetch}
      setMessage={setMessage}
      messages={messagesWithFilePath ?? []}
      sendMessage={sendMessage}
      filesToSend={filesToSend}
      setFilesToSend={(files: File[]) => setFilesToSend(files)}    />
  )
};


export default NewOrderView;
