import {
  AbutmentType,
  RetentionType,
  OcclusalContactTightness,
  ProximalContactTightness,
  OcclusalStaining,
  NoOcclusalClearance,
  InterproximalContactDesign,
  AnteriorPosteriorPonticDesign,
} from "./enums";

export const STYLE = {
  primary: "#4362D0",
  border: "#ebf0f6",
};

export const YES_OR_NO_CHOICE = [
  {
    value: true,
    label: "Yes"
  },
  {
    value: false,
    label: "False"
  }
]

export const TOGGLE_FEATURE = {
  NEW_WORKFLOW: true,
  CHAT: true,
  QR_CODE : true,
};

export const AbutmentTypeChoices = [
  {value: AbutmentType.STANDARD, label: "Standard"},
  {value: AbutmentType.CUSTOM, label: "Personnalisé"},
];

export const StandardAbutmentTypeChoices = [
  {value: AbutmentType.STANDARD, label: "Pilier original - fabriquant"},
  {value: AbutmentType.STANDARD_GENERIC, label: "Pilier standard générique"},
];

export const RetentionTypeChoices = [
  {value: RetentionType.CEMENT_RETAINED, label: "Scellé"},
  {value: RetentionType.SCREW_RETAINED, label: "Transvissé"},
];

export const OcclusalContactTightnessLabels = [
  {
    value: OcclusalContactTightness.HEAVY,
    label: "Forte"
  },
  {
    value: OcclusalContactTightness.MEDIUM,
    label: "Moyenne - par défaut",
  },
  {
    value: OcclusalContactTightness.LIGHT,
    label: "Légère"
  },
];

export const ProximalContactTightnessLabels = [
  {
    value: ProximalContactTightness.HEAVY,
    label: "Fort"
  },
  {
    value: ProximalContactTightness.MEDIUM,
    label: "Moyen - par défaut",
  },
  {
    value: ProximalContactTightness.LIGHT,
    label: "Léger"
  },
  {
    value: ProximalContactTightness.NONE,
    label: "Aucun"
  },
];

export const OcclusalStainingLabels = [
  {
    value: OcclusalStaining.DARK,
    label: "Foncé"
  },
  {
    value: OcclusalStaining.MEDIUM,
    label: "Moyen"
  },
  {
    value: OcclusalStaining.LIGHT,
    label: "Clair"
  },
  {
    value: OcclusalStaining.NONE,
    label: "Aucun"
  },
];

export const NoOcclusalClearanceLabels = [
  {
    value: NoOcclusalClearance.ASK_DENTIST,
    label: "Consulter le praticien",
  },
  {
    value: NoOcclusalClearance.KEEP_MANUFACTURING,
    label: "Poursuivre la fabrication",
  },
];

export const InterproximalContactDesignLabels = [
  {
    value: InterproximalContactDesign.NORMAL_CONTACT_POINT,
    label: "Point de contact",
  },
  {
    value: InterproximalContactDesign.WIDE_CONTACT_SURFACE,
    label: "Surface de contact",
  },
];

export const AnteriorPosteriorPonticDesignLabels = [
  {
    value: AnteriorPosteriorPonticDesign.MODIFIED_RIDGE_LAP,
    label: "Pontique crestal modifié",
  },
  {
    value: AnteriorPosteriorPonticDesign.FULL_RIDGE_LAP,
    label: "Complet"
  },
];
