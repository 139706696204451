import { useMutation, useQuery } from '@apollo/client';
import { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Menu, Button as MantimeButton, Table, TextInput } from '@mantine/core';
import { Button } from '/@/composants/';
import {
  GET_ALL_ORDERS_FOR_STAFF,
} from '/@/gql/';
import {
  getToShipDatetime,
  getTodayTimestamp,
} from "/@/utils/date.utils";
import { isImplant, isOrderWithNoAttachement } from '/@/utils/product.utils';
import { Order } from '/@/types/interfaces';
import { ItemType } from '/@/types/enums';
import { UseAuth } from '/@/contexts';

import {
  UserType,
  OrderStatus,
  UPDATE_MANY_ORDER,
  isUserProvider,
  isUserStaff,
} from '@jasper/shared';

const LIMIT_PAGINATION_QUANTITY = 20;


const OrderListTable = (
  {
    data,
    navigate,
    status,
    setListOrderToUpdate,
    toggleList,
    user,
    selectedValues,
  }
) => {  

  const getBackgroundColor = (order: Order) => {
    if (isPAC(order)){
      return ({ backgroundColor: "blue", color: "white" })
    };
    if ((order?.redo ?? []).length > 0){
      return ({ backgroundColor: "purple", color: "white" })
    }
    if (isImplant(order) && order?.comment){
      return ({backgroundColor: "red", color: "white"});
    }
    if (isImplant(order)){
      return ({backgroundColor: "darkred", color: "white"});
    }
    if ((order?.orderComment ?? []).length > 0){
      return ({backgroundColor: "lightcoral", color: "white"});
    }
    return ("");
  };

  const isPAC = (order: Order) => {
    return (order?.products ?? []).find((product) => product.productType === ItemType.PARTIAL_DENTURE);
  };

  const displayLastUpdate = (dt: date) => {
      const lastUpdateInDays = Math.round(Math.abs((dt - new Date()) / (24 * 60 * 60 * 1000)));
      if (lastUpdateInDays >= 1){
        return (dt.toDateString())
      }
      return (dt.toLocaleTimeString());    
  }

  useEffect(()=>{
    setListOrderToUpdate([])
  }, [status])

  return (
    <Table>
      <Table.Thead>
        <Table.Tr>
          {
            (isUserStaff(user)) && (
              <Table.Th></Table.Th>
            )
          }
          <Table.Th>Note</Table.Th>
          <Table.Th>Reference</Table.Th>
          <Table.Th>Status</Table.Th>
          <Table.Th>Doctor</Table.Th>
          <Table.Th>Patient</Table.Th>
          <Table.Th>Product</Table.Th>
          {isUserStaff(user) &&
            <>
              <Table.Th>Delivery Date</Table.Th>
              <Table.Th>Last update</Table.Th>
            </>
          }
          {isUserProvider(user) &&
            <Table.Th>Last shipping date</Table.Th>
          }
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>
        {(data ?? []).map((order: Order) => (
          <Table.Tr
            style={{ cursor: "pointer" }}
            key={order.id}
          >
            {
              (isUserStaff(user))
               && (
                <Table.Td>
                  <input
                    type='checkbox' 
                    value={order.id}
                    name='selectOrder'
                    checked={selectedValues.includes(order.id)}
                    onClick={()=> toggleList(order.id)}
                  />
                </Table.Td>
              )
            }
            <Table.Td
              onClick={() => navigate(`/orders/${order.id}`)}
              style={getBackgroundColor(order)}
            >
              {(order?.isRush ?? false) && "RUSH "}
              {((order?.orderComment ?? []).length > 0) && " COMMENT "}
              {isImplant(order) && "IMPLANT "}
              {isOrderWithNoAttachement(order) && "NO ATTACHEMENT "}
              {isPAC(order) && "PAC "}
              {((order?.redo ?? []).length > 0) && "REDO"}
            </Table.Td>
            <Table.Td onClick={() => navigate(`/orders/${order.id}`)}>{order.orderReference}</Table.Td>
            <Table.Td onClick={() => navigate(`/orders/${order.id}`)}>{order.status}</Table.Td>
            <Table.Td onClick={() => navigate(`/orders/${order.id}`)}>Dr {order?.user?.firstName} {order?.user?.lastName}</Table.Td>
            <Table.Td onClick={() => navigate(`/orders/${order.id}`)}>{order?.patient?.firstName} {order?.patient?.lastName}</Table.Td>
            <Table.Td onClick={() => navigate(`/orders/${order.id}`)}>{(order.products ?? []).lenght > 1 ? "Multiple" : order.products[0]?.productType}</Table.Td>
            {isUserStaff(user) &&
              <>
                <Table.Td onClick={() => navigate(`/orders/${order.id}`)}>{order.deliveryDate ? new Date(order.deliveryDate).toDateString() : "-"}</Table.Td>
                <Table.Td onClick={() => navigate(`/orders/${order.id}`)}>{displayLastUpdate(new Date(order.updatedAt ?? order.createdAt))}</Table.Td>
              </>
            }
            {isUserProvider(user) &&
              <Table.Td onClick={() => navigate(`/orders/${order.id}`)}>{order?.lastShippingDate ? new Date(order?.lastShippingDate).toDateString() : "-"}</Table.Td>
            }
          </Table.Tr>
        ))}
      </Table.Tbody>
    </Table>
  ); 
};

const todayTimestamp = getTodayTimestamp();

const Home = () => {

  const [listOrderToUpdate, setListOrderToUpdate] = useState<string[]>([]);
  const [updateOrderStatus] = useMutation(UPDATE_MANY_ORDER);

  const toggleList = (id : string) => {
    const foundOrder = listOrderToUpdate.find(val => val === id)
    if(foundOrder !== undefined) {
      setListOrderToUpdate(listOrderToUpdate.filter(val => val !== id))
    }
    else{
      setListOrderToUpdate([...listOrderToUpdate,id])
    }
  };

  const [searchText, setSearchText] = useState<string>("");

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { user } = UseAuth();

  const getStatus = () => {
    if (isUserProvider(user)){
      return OrderStatus.PRODUCING;
    };
    if (isUserStaff(user)){
      return searchParams.get('status');
    };
  };

  const getOrderListFilters = () => {
    const filters = {};
    if (searchParams.get("status") === "ARCHIVED"){
      filters["isArchived"] = {
        equals: true
      };
    }
    if (searchParams.get("status") === "TO_SHIP"){
      filters["lastShippingDate"] = {
        lte: todayTimestamp,
      }
      filters["status"] = {
        in: [OrderStatus.WAITING_FOR_PRODUCTION, OrderStatus.PRODUCING]
      }
    }
    if (
      searchParams.get('status')
        && searchParams.get('status') !== "ALL"
        && searchParams.get('status') !== "TO_SHIP"
        && searchParams.get('status') !== "ARCHIVED"
    ){
      filters['status'] = {
        equals: searchParams.get('status'),
      }
      filters['isArchived'] = {
        equals: false,
      }
    };
    if (searchText){
      filters['OR'] = [{
        orderReference: {
          contains: searchText,
          mode: "insensitive",
        },
      }, {
        patient: {
          is: {
            firstName: {
              contains: searchText,
              mode: "insensitive",
            }
          }
        }
      }, {
        patient: {
          is: {
            lastName: {
              contains: searchText,
              mode: "insensitive",
            }
          }
        }
      }, {
        user: {
          is: {
            firstName: {
              contains: searchText,
              mode: "insensitive",
            }
          }
        }
      }, {
        user: {
          is: {
            lastName: {
              contains: searchText,
              mode: "insensitive",
            }
          }
        }
      }]
    }
    return (filters);
  }

  const { data, refetch } = useQuery(
    GET_ALL_ORDERS_FOR_STAFF,
    {
      variables: {
        skip: LIMIT_PAGINATION_QUANTITY * (searchParams.get('page') ?? 0),
        take: LIMIT_PAGINATION_QUANTITY,
        where: getOrderListFilters(),
      },
      fetchPolicy: "network-only",
    },
  );

  return (
    <div>
      <div style={{display:'flex'}}>
        <TextInput
          style={{
            width:'100%',
            marginRight:'10px'
          }}
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          placeholder="Search by order reference, firstname or lastname of patient/doctor"
        />
        <Menu>
          <Menu.Target>
            <MantimeButton style={{width:'100px'}}>Actions</MantimeButton>
          </Menu.Target>
          <Menu.Dropdown>
            {
              Object.keys(OrderStatus).filter((status => searchParams.get('status') !== status)).map((item : string, index : number) => 
                <Menu.Item
                  key={index}
                  onClick={
                    () => {
                      if(confirm("You are about to update the status of "+listOrderToUpdate.length+" orders to  : " + item)) {
                        updateOrderStatus(
                          {
                            variables: {
                              where: {
                                id: {
                                  in: listOrderToUpdate
                                },
                              },
                              data: {
                                status: {
                                  set: item,
                                },
                              },
                            },
                          }
                        ).then(()=>{
                          setListOrderToUpdate([])
                          refetch()
                        })
                      }
                    }
                  }
                >
                  {item}
                </Menu.Item>
               )
            }
          </Menu.Dropdown>
        </Menu>
      </div>
      <OrderListTable
        user={user}
        data={data?.getAllOrdersForStaff ?? []}
        navigate={(url: string) => navigate(url)}
        status={searchParams.get('status')}
        setListOrderToUpdate={setListOrderToUpdate}
        toggleList={toggleList}
        selectedValues={listOrderToUpdate}
      />
      {((data?.getAllOrdersForStaff ?? [])).length > 0 &&
        <div style={{ display: 'flex', justifyContent: "space-between" }}>
          <div>
            {searchParams.get("page") > 0 &&
              <Button
                value="Previous page"
                onClick={() => {
                  navigate(`/home?status=${getStatus()}&page=${parseInt(searchParams.get('page') ?? 0, 10) - 1}`)
                }}
              />
            }
          </div>
          <div style={{ marginTop: "1rem" }}>
            Page {searchParams.get("page")}{ listOrderToUpdate.length !== 0 && 
              <span>, {listOrderToUpdate.length} commandes sélectionnées</span>
            }
          </div>
          <div>
            {(data?.getAllOrdersForStaff ?? []).length % LIMIT_PAGINATION_QUANTITY === 0 &&
              <Button
                value="Next page"
                onClick={() => {
                  navigate(`/home?status=${getStatus()}&page=${parseInt(searchParams.get('page') ?? 0, 10) + 1}`)
                }}
              />
            }
          </div>
        </div>
      }
    </div>
  )
};

export default Home;
