import React, {useEffect} from "react";
import {
  useMutation,
  useQuery,
} from "@apollo/client";
import {
  useNavigate,
} from "react-router-dom";

import {
  GET_LAST_UNREAD_MESSAGES_OF_EACH_ORDER,
  UPDATE_MESSAGES,
} from "/@/gql/";
import { Button, Table } from "@mantine/core";
import { UseAuth } from '/@/contexts';
import { getApiUrl } from '../../config';
import {io} from "socket.io-client";

const ChatList = () => {

  const navigate = useNavigate();

  const { user } = UseAuth();
  const { data, refetch } = useQuery(GET_LAST_UNREAD_MESSAGES_OF_EACH_ORDER);
  const [updateMessages] = useMutation(UPDATE_MESSAGES);
  const markAsViewed = (orderId : string) => {
    updateMessages(
      {
        variables: {
          data: {
            read: {
              set: true
            }
          },
          where: {
            orderId: {
              equals: orderId
            },
            userGroupId: {
              not: {
                equals: user?.userGroupId
              }
            }
          }
        }
      }
    )
  }

  useEffect(() => {
    const socket = io(getApiUrl(),
    {
      query: {
        type: 'notification'
      },
    });
    
    socket.connect()

    socket.on('notification', () => {
      refetch()
    })
    return () => {
      socket.disconnect()
      socket.off('connect')
      socket.off('disconnect')
      socket.off('notification')
    }
  }, [])

  return (
    <Table>
      <Table.Thead>
        <Table.Tr>
          <Table.Th>Order references</Table.Th>
          <Table.Th>Status</Table.Th>
          <Table.Th>Last unread messages</Table.Th>
          <Table.Th>Actions</Table.Th>
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>
        {data && data.getLastUnreadOfEachOrder.map((chat) => {
          return (
            <>
              <Table.Tr key={chat.order.id}>
                <Table.Td>{chat.order.orderReference}</Table.Td>
                <Table.Td>{chat.order.status}</Table.Td>
                <Table.Td>{chat.message}</Table.Td>
                <Table.Td style={{
                  display: 'grid',
                  gridAutoFlow: 'column',
                  gap: '5px'
                }}>
                  <Button onClick={() => navigate(`/orders/${chat.order.id}`)}>
                    View order
                  </Button>
                  <Button onClick={() => markAsViewed(chat.order.id)}>
                    Mark as viewed
                  </Button>
                </Table.Td>
              </Table.Tr>
            </>
          )
        })}
      </Table.Tbody>
    </Table>
  );
};

export default ChatList;
